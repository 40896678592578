/* Custom CSS */
.ant-menu-horizontal .ant-menu-item {
  padding-left: 5px;
  padding-right: 5px;
}
.ant-menu-horizontal .ant-menu-item::after {
  border: none !important;
  transition: none !important;
}
.ant-menu-horizontal:hover .ant-menu-item::after {
  border: none;
  transition: none;
}

.sidebar .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
  background: blue;
  color: white;
}

.sidebar .ant-menu-item.ant-menu-item-only-child:not(:last-child) {
  height: 70px;
}

.sidebar .ant-menu-item.ant-menu-item-only-child {
  padding-left: 30px !important;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background-color: transparent;
  /* border: 1px solid transparent; */
}

*::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #aaa;
}

*:hover::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  cursor: pointer;
}

*:hover::-webkit-scrollbar-track {
  /* border-radius: 5px; */
  background-color: transparent;
  /* border: 1px solid transparent; */
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}

.price-card .ant-card-body {
  height: 100%;
}